import IconClose from "../SVG/IconClose";
import LogoMenu from "../SVG/LogoMenu";
import Logo from "../SVG/Logo";

import React, { Component } from "react";
import { NavLink, Link, withRouter } from "react-router-dom";
import MobileMenu from "../MobileMenu/MobileMenu";
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.hash !== this.props.location.hash) {
      if (this.props.location.hash !== "") {
        this.setState({
          menuOpen: false,
        });
      }
    }

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        menuOpen: false,
      });
    }
  }

  render() {
    return (
      <nav className="navbar navbar-expand-lg sticky-top">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <Logo size="50px" />
          </Link>

          <button
            onClick={this.toggleMenu}
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {this.state.menuOpen ? <IconClose color="gray" /> : <LogoMenu />}
            {this.state.menuOpen}
          </button>

          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item active">
                <NavLink
                  exact
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/"
                >
                  <span className="font-small font-weight-bold ml-5">Home</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/about"
                >
                  <span className="font-small font-weight-bold ml-5">
                    About Us
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/school"
                >
                  <span className="font-small font-weight-bold ml-5">
                    IT School
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/development"
                >
                  <span className="font-small font-weight-bold ml-5">
                    Development
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/client"
                >
                  <span className="font-small font-weight-bold ml-5">
                    Client
                  </span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="text-black"
                  activeClassName="navbar-link-active"
                  to="/contact"
                >
                  <span className="font-small font-weight-bold ml-5">
                    Contact Us
                  </span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        {this.state.menuOpen && <MobileMenu />}
      </nav>
    );
  }
}

export default withRouter(Navbar);
