import React from 'react';
import { NavLink } from 'react-router-dom';

import Reveal from 'react-reveal/Reveal';

const MobileMenu = () => {
	return (
		<div className="mobile-menu-wrapper">
			<Reveal effect="zoomIn">
				<ul className="mobile-menu-list">
					<li className="mobile-menu-item mb-2">
						<NavLink exact className="text-black" activeClassName="menu-active" to="/">
							<h6 className="font-weight-bold font-uppercase mb-3">Home</h6>
						</NavLink>
					</li>
					<li className="mobile-menu-item mb-2">
						<NavLink className="text-black" activeClassName="menu-active" to="/about">
							<h6 className="font-weight-bold font-uppercase mb-3">About Us</h6>
						</NavLink>
					</li>
					<li className="mobile-menu-item mb-2">
						<NavLink className="text-black" activeClassName="menu-active" to="/school">
							<h6 className="font-weight-bold font-uppercase mb-3">IT School</h6>
						</NavLink>
					</li>
					<li className="mobile-menu-item mb-2">
						<NavLink className="text-black" activeClassName="menu-active" to="/development">
							<h6 className="font-weight-bold font-uppercase mb-3">Development</h6>
						</NavLink>
					</li>
					<li className="mobile-menu-item mb-2">
						<NavLink className="text-black" activeClassName="menu-active" to="/client">
							<h6 className="font-weight-bold font-uppercase mb-3">Client</h6>
						</NavLink>
					</li>
					<li className="mobile-menu-item mb-2">
						<NavLink className="text-black" activeClassName="menu-active" to="/contact">
							<h6 className="font-weight-bold font-uppercase mb-3">Contact Us</h6>
						</NavLink>
					</li>
				</ul>
			</Reveal>
    	</div>
	);
};

export default MobileMenu;
