import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import GetStarted from './components/GetStarted/GetStarted';
import ScrollTop from './components/ScrollTop/ScrollTop';

import Home from './containers/Home/Home';
import About from './containers/About/About';
import School from './containers/School/School';
import Development from './containers/Development/Development';
// import News from './containers/News/News';
import NewsDetail from './containers/NewsDetail/NewsDetail';
import Client from './containers/Client/Client';
import Contact from './containers/Contact/Contact';

import './animate.css';
import './App.scss';

const NoMatch = () => (
	<div className="pt-5">
		<h2 className="text-center">404 Not Found</h2>
		<p className="App-intro text-center">The page you were looking for does not exist.</p>
	</div>
);

function App({ hideLoader }) {
	useEffect(() => {
		hideLoader();
	});

	return (
		<Router>
			<Navbar />
			<Switch>
				<Route exact path="/" component={Home} />
				<Route exact path="/about" component={About} />
				<Route exact path="/school" component={School} />
				<Route exact path="/development" component={Development} />
				{/* <Route exact path="/news" component={News} /> */}
				<Route exact path="/news/1" component={NewsDetail} />
				<Route exact path="/client" component={Client} />
				<Route exact path="/contact" component={Contact} />
				<Route component={NoMatch} />
			</Switch>
			<GetStarted />
			<Footer />
			<ScrollTop />
		</Router>
	);
}

export default App;
