import React from 'react';

const Logo = ({ size = 24 }) => {
  return (
    <svg viewBox="0 0 200 50" height={size}>
      <g>
        <g>
        <g>
          <path d="M48.42,40.67v-4h0.36v4H48.42z"/>
          <path d="M51.75,37v3.67h-0.36V37h-1.82v-0.33h4V37H51.75z"/>
          <path d="M59.83,37.26c-0.08-0.04-0.16-0.08-0.26-0.12c-0.1-0.04-0.21-0.08-0.34-0.11c-0.13-0.03-0.27-0.06-0.43-0.08
            s-0.34-0.03-0.53-0.03c-0.24,0-0.44,0.01-0.63,0.04s-0.34,0.07-0.47,0.13c-0.13,0.06-0.22,0.13-0.29,0.22
            c-0.07,0.09-0.1,0.19-0.1,0.31c0,0.1,0.03,0.19,0.09,0.26c0.06,0.08,0.15,0.14,0.26,0.2s0.23,0.11,0.38,0.16
            c0.14,0.05,0.3,0.09,0.46,0.13c0.16,0.04,0.33,0.08,0.5,0.13s0.34,0.09,0.5,0.14c0.16,0.05,0.32,0.1,0.46,0.17
            c0.14,0.06,0.27,0.13,0.38,0.21c0.11,0.08,0.19,0.17,0.26,0.28c0.06,0.1,0.09,0.23,0.09,0.36c0,0.15-0.03,0.28-0.08,0.39
            c-0.06,0.11-0.13,0.21-0.23,0.29c-0.1,0.08-0.21,0.15-0.34,0.2c-0.13,0.05-0.27,0.1-0.41,0.13s-0.3,0.05-0.45,0.07
            s-0.31,0.02-0.46,0.02c-0.35,0-0.66-0.02-0.94-0.08s-0.55-0.12-0.81-0.21v-0.45c0.26,0.16,0.54,0.27,0.84,0.32
            c0.29,0.06,0.6,0.09,0.93,0.09c0.23,0,0.44-0.01,0.63-0.04s0.36-0.07,0.5-0.13c0.14-0.06,0.25-0.14,0.33-0.23
            c0.08-0.09,0.12-0.21,0.12-0.35c0-0.11-0.03-0.21-0.09-0.29c-0.06-0.08-0.15-0.16-0.26-0.22c-0.11-0.06-0.23-0.12-0.38-0.17
            s-0.3-0.09-0.46-0.14c-0.16-0.04-0.33-0.08-0.5-0.12s-0.34-0.08-0.5-0.13c-0.16-0.05-0.31-0.1-0.46-0.16
            c-0.14-0.06-0.27-0.13-0.38-0.21c-0.11-0.08-0.19-0.17-0.26-0.27c-0.06-0.1-0.09-0.22-0.09-0.36c0-0.13,0.03-0.25,0.08-0.36
            c0.05-0.1,0.12-0.19,0.21-0.27c0.09-0.08,0.19-0.14,0.31-0.19c0.12-0.05,0.25-0.09,0.38-0.12c0.13-0.03,0.28-0.05,0.42-0.07
            c0.15-0.01,0.29-0.02,0.43-0.02c0.16,0,0.3,0.01,0.44,0.02c0.14,0.01,0.27,0.03,0.4,0.05c0.13,0.02,0.25,0.05,0.38,0.08
            c0.12,0.03,0.25,0.06,0.38,0.09V37.26z"/>
          <path d="M64.98,40.51c-0.14,0.05-0.28,0.09-0.43,0.13s-0.3,0.06-0.47,0.08c-0.16,0.02-0.34,0.03-0.53,0.03
            c-0.24,0-0.46-0.02-0.68-0.06c-0.22-0.04-0.42-0.1-0.61-0.17c-0.19-0.08-0.36-0.17-0.51-0.29c-0.15-0.12-0.29-0.25-0.39-0.4
            c-0.11-0.15-0.19-0.33-0.25-0.52c-0.06-0.19-0.09-0.4-0.09-0.63c0-0.23,0.03-0.44,0.09-0.63c0.06-0.19,0.14-0.36,0.25-0.52
            c0.11-0.15,0.24-0.29,0.39-0.4c0.15-0.12,0.32-0.21,0.51-0.29c0.19-0.08,0.39-0.14,0.61-0.17c0.22-0.04,0.45-0.06,0.68-0.06
            c0.19,0,0.37,0.01,0.53,0.03c0.16,0.02,0.32,0.05,0.47,0.08s0.29,0.08,0.43,0.12c0.14,0.05,0.28,0.1,0.42,0.16v0.44
            c-0.11-0.07-0.23-0.14-0.36-0.2c-0.13-0.06-0.26-0.12-0.41-0.16c-0.15-0.05-0.3-0.08-0.47-0.11c-0.17-0.03-0.35-0.04-0.54-0.04
            c-0.38,0-0.72,0.04-1,0.13c-0.28,0.08-0.51,0.2-0.7,0.35s-0.32,0.34-0.41,0.55c-0.09,0.22-0.13,0.46-0.13,0.73
            s0.04,0.51,0.13,0.72c0.09,0.22,0.22,0.4,0.41,0.55s0.42,0.27,0.7,0.35c0.28,0.08,0.61,0.12,1,0.12c0.19,0,0.37-0.01,0.54-0.04
            c0.17-0.03,0.33-0.06,0.48-0.11c0.15-0.05,0.29-0.1,0.41-0.16c0.13-0.06,0.24-0.13,0.34-0.19v0.43
            C65.26,40.41,65.12,40.47,64.98,40.51z"/>
          <path d="M70.42,40.67v-1.94h-3.58v1.94h-0.36v-4h0.36v1.72h3.58v-1.72h0.36v4H70.42z"/>
          <path d="M76.93,38.67c0,0.36-0.06,0.67-0.19,0.93c-0.13,0.26-0.31,0.48-0.54,0.65c-0.23,0.17-0.5,0.3-0.8,0.38
            c-0.31,0.08-0.64,0.12-1,0.12c-0.36,0-0.69-0.04-1-0.12c-0.31-0.08-0.58-0.21-0.81-0.38c-0.23-0.17-0.41-0.38-0.54-0.65
            c-0.13-0.26-0.2-0.57-0.2-0.93c0-0.24,0.03-0.46,0.09-0.65c0.06-0.2,0.14-0.37,0.25-0.52s0.24-0.29,0.39-0.4
            c0.15-0.11,0.33-0.21,0.51-0.28c0.19-0.08,0.39-0.13,0.61-0.17c0.22-0.04,0.45-0.05,0.68-0.05c0.36,0,0.69,0.04,1,0.12
            c0.31,0.08,0.58,0.21,0.8,0.38c0.23,0.17,0.41,0.38,0.54,0.65C76.87,38.01,76.93,38.32,76.93,38.67z M76.56,38.67
            c0-0.28-0.05-0.53-0.14-0.75c-0.09-0.22-0.23-0.4-0.41-0.55s-0.41-0.26-0.67-0.34c-0.27-0.08-0.58-0.12-0.94-0.12
            s-0.67,0.04-0.94,0.12c-0.27,0.08-0.5,0.19-0.68,0.34s-0.32,0.33-0.41,0.55c-0.09,0.22-0.14,0.47-0.14,0.75
            c0,0.28,0.05,0.53,0.14,0.75c0.09,0.22,0.23,0.4,0.41,0.55s0.41,0.26,0.68,0.34c0.27,0.08,0.58,0.12,0.94,0.12
            s0.67-0.04,0.94-0.12c0.27-0.08,0.49-0.19,0.67-0.34s0.32-0.33,0.41-0.55C76.51,39.21,76.56,38.96,76.56,38.67z"/>
          <path d="M82.8,38.67c0,0.36-0.06,0.67-0.19,0.93c-0.13,0.26-0.31,0.48-0.54,0.65c-0.23,0.17-0.5,0.3-0.8,0.38
            c-0.31,0.08-0.64,0.12-1,0.12c-0.36,0-0.69-0.04-1-0.12c-0.31-0.08-0.58-0.21-0.81-0.38c-0.23-0.17-0.41-0.38-0.54-0.65
            c-0.13-0.26-0.2-0.57-0.2-0.93c0-0.24,0.03-0.46,0.09-0.65c0.06-0.2,0.14-0.37,0.25-0.52s0.24-0.29,0.39-0.4
            c0.15-0.11,0.33-0.21,0.51-0.28c0.19-0.08,0.39-0.13,0.61-0.17c0.22-0.04,0.45-0.05,0.68-0.05c0.36,0,0.69,0.04,1,0.12
            c0.31,0.08,0.58,0.21,0.8,0.38c0.23,0.17,0.41,0.38,0.54,0.65C82.73,38.01,82.8,38.32,82.8,38.67z M82.43,38.67
            c0-0.28-0.05-0.53-0.14-0.75c-0.09-0.22-0.23-0.4-0.41-0.55s-0.41-0.26-0.67-0.34c-0.27-0.08-0.58-0.12-0.94-0.12
            s-0.67,0.04-0.94,0.12c-0.27,0.08-0.5,0.19-0.68,0.34s-0.32,0.33-0.41,0.55c-0.09,0.22-0.14,0.47-0.14,0.75
            c0,0.28,0.05,0.53,0.14,0.75c0.09,0.22,0.23,0.4,0.41,0.55s0.41,0.26,0.68,0.34c0.27,0.08,0.58,0.12,0.94,0.12
            s0.67-0.04,0.94-0.12c0.27-0.08,0.49-0.19,0.67-0.34s0.32-0.33,0.41-0.55C82.38,39.21,82.43,38.96,82.43,38.67z"/>
          <path d="M83.87,40.67v-4h0.36v3.66h2.92v0.34H83.87z"/>
          <path d="M94.53,38.78c-0.03,0.23-0.09,0.46-0.2,0.68c-0.11,0.22-0.26,0.43-0.46,0.61c0.06,0.04,0.12,0.08,0.18,0.13
            c0.06,0.04,0.13,0.08,0.2,0.13c0.07,0.04,0.15,0.1,0.23,0.15c0.09,0.05,0.18,0.12,0.3,0.19h-0.53c-0.11-0.07-0.22-0.14-0.33-0.2
            c-0.1-0.07-0.2-0.13-0.31-0.2c-0.21,0.14-0.45,0.26-0.74,0.35c-0.29,0.09-0.62,0.13-1,0.13c-0.23,0-0.46-0.02-0.67-0.06
            c-0.22-0.04-0.41-0.11-0.57-0.2c-0.17-0.09-0.3-0.2-0.4-0.34s-0.15-0.3-0.15-0.49c0-0.13,0.03-0.26,0.09-0.37
            s0.14-0.22,0.25-0.32c0.1-0.1,0.22-0.19,0.36-0.28c0.14-0.09,0.28-0.17,0.43-0.24c-0.18-0.18-0.31-0.35-0.39-0.51
            c-0.08-0.16-0.13-0.31-0.13-0.45s0.04-0.28,0.11-0.39c0.07-0.11,0.17-0.2,0.29-0.28c0.12-0.07,0.27-0.13,0.43-0.16
            c0.17-0.04,0.34-0.05,0.53-0.05c0.16,0,0.32,0.02,0.46,0.05s0.26,0.08,0.37,0.14c0.1,0.06,0.19,0.14,0.25,0.22
            c0.06,0.09,0.09,0.19,0.09,0.29c0,0.13-0.04,0.25-0.13,0.36c-0.09,0.11-0.2,0.21-0.34,0.31c-0.14,0.1-0.3,0.19-0.47,0.28
            c-0.18,0.09-0.36,0.17-0.54,0.26c0.21,0.18,0.46,0.39,0.77,0.61c0.31,0.23,0.67,0.48,1.1,0.77c0.17-0.15,0.31-0.33,0.4-0.52
            c0.09-0.19,0.15-0.39,0.17-0.6H94.53z M91.94,40.44c0.29,0,0.56-0.03,0.79-0.1c0.23-0.06,0.44-0.15,0.62-0.26
            c-0.42-0.28-0.79-0.53-1.11-0.77c-0.32-0.23-0.59-0.45-0.81-0.66c-0.06,0.03-0.12,0.06-0.2,0.1c-0.07,0.04-0.14,0.08-0.22,0.13
            c-0.07,0.05-0.15,0.1-0.22,0.15c-0.07,0.05-0.13,0.11-0.19,0.18c-0.05,0.06-0.1,0.13-0.13,0.21c-0.03,0.07-0.05,0.15-0.05,0.23
            c0,0.1,0.03,0.2,0.09,0.29c0.06,0.09,0.15,0.18,0.27,0.25c0.12,0.07,0.28,0.13,0.47,0.18S91.67,40.44,91.94,40.44z M91.05,37.48
            c0,0.12,0.04,0.25,0.11,0.38c0.08,0.13,0.19,0.28,0.36,0.44c0.17-0.08,0.34-0.16,0.5-0.23c0.16-0.07,0.3-0.15,0.43-0.23
            c0.13-0.08,0.23-0.16,0.3-0.24c0.08-0.08,0.11-0.17,0.11-0.26c0-0.05-0.01-0.1-0.04-0.15c-0.03-0.05-0.07-0.1-0.13-0.14
            c-0.06-0.04-0.14-0.07-0.24-0.1c-0.1-0.02-0.23-0.04-0.38-0.04c-0.15,0-0.29,0.01-0.41,0.03s-0.23,0.06-0.32,0.1
            c-0.09,0.05-0.16,0.11-0.21,0.18S91.05,37.38,91.05,37.48z"/>
          <path d="M101.29,37.26c-0.08-0.04-0.16-0.08-0.26-0.12c-0.1-0.04-0.21-0.08-0.34-0.11c-0.13-0.03-0.27-0.06-0.43-0.08
            s-0.34-0.03-0.53-0.03c-0.24,0-0.44,0.01-0.63,0.04s-0.34,0.07-0.47,0.13c-0.13,0.06-0.22,0.13-0.29,0.22
            c-0.07,0.09-0.1,0.19-0.1,0.31c0,0.1,0.03,0.19,0.09,0.26c0.06,0.08,0.15,0.14,0.26,0.2s0.23,0.11,0.38,0.16
            c0.14,0.05,0.3,0.09,0.46,0.13c0.16,0.04,0.33,0.08,0.5,0.13s0.34,0.09,0.5,0.14c0.16,0.05,0.32,0.1,0.46,0.17
            c0.14,0.06,0.27,0.13,0.38,0.21c0.11,0.08,0.19,0.17,0.26,0.28c0.06,0.1,0.09,0.23,0.09,0.36c0,0.15-0.03,0.28-0.08,0.39
            c-0.06,0.11-0.13,0.21-0.23,0.29c-0.1,0.08-0.21,0.15-0.34,0.2c-0.13,0.05-0.27,0.1-0.41,0.13s-0.3,0.05-0.45,0.07
            s-0.31,0.02-0.46,0.02c-0.35,0-0.66-0.02-0.94-0.08s-0.55-0.12-0.81-0.21v-0.45c0.26,0.16,0.54,0.27,0.84,0.32
            c0.29,0.06,0.6,0.09,0.93,0.09c0.23,0,0.44-0.01,0.63-0.04s0.36-0.07,0.5-0.13c0.14-0.06,0.25-0.14,0.33-0.23
            c0.08-0.09,0.12-0.21,0.12-0.35c0-0.11-0.03-0.21-0.09-0.29c-0.06-0.08-0.15-0.16-0.26-0.22c-0.11-0.06-0.23-0.12-0.38-0.17
            s-0.3-0.09-0.46-0.14c-0.16-0.04-0.33-0.08-0.5-0.12s-0.34-0.08-0.5-0.13c-0.16-0.05-0.31-0.1-0.46-0.16
            c-0.14-0.06-0.27-0.13-0.38-0.21c-0.11-0.08-0.19-0.17-0.26-0.27c-0.06-0.1-0.09-0.22-0.09-0.36c0-0.13,0.03-0.25,0.08-0.36
            c0.05-0.1,0.12-0.19,0.21-0.27c0.09-0.08,0.19-0.14,0.31-0.19c0.12-0.05,0.25-0.09,0.38-0.12c0.13-0.03,0.28-0.05,0.42-0.07
            c0.15-0.01,0.29-0.02,0.43-0.02c0.16,0,0.3,0.01,0.44,0.02c0.14,0.01,0.27,0.03,0.4,0.05c0.13,0.02,0.25,0.05,0.38,0.08
            c0.12,0.03,0.25,0.06,0.38,0.09V37.26z"/>
          <path d="M107.55,38.67c0,0.36-0.06,0.67-0.19,0.93c-0.13,0.26-0.31,0.48-0.54,0.65c-0.23,0.17-0.5,0.3-0.8,0.38
            c-0.31,0.08-0.64,0.12-1,0.12c-0.36,0-0.69-0.04-1-0.12c-0.31-0.08-0.58-0.21-0.81-0.38c-0.23-0.17-0.41-0.38-0.54-0.65
            c-0.13-0.26-0.2-0.57-0.2-0.93c0-0.24,0.03-0.46,0.09-0.65c0.06-0.2,0.14-0.37,0.25-0.52s0.24-0.29,0.39-0.4
            c0.15-0.11,0.33-0.21,0.51-0.28c0.19-0.08,0.39-0.13,0.61-0.17c0.22-0.04,0.45-0.05,0.68-0.05c0.36,0,0.69,0.04,1,0.12
            c0.31,0.08,0.58,0.21,0.8,0.38c0.23,0.17,0.41,0.38,0.54,0.65C107.48,38.01,107.55,38.32,107.55,38.67z M107.17,38.67
            c0-0.28-0.05-0.53-0.14-0.75c-0.09-0.22-0.23-0.4-0.41-0.55s-0.41-0.26-0.67-0.34c-0.27-0.08-0.58-0.12-0.94-0.12
            s-0.67,0.04-0.94,0.12c-0.27,0.08-0.5,0.19-0.68,0.34s-0.32,0.33-0.41,0.55c-0.09,0.22-0.14,0.47-0.14,0.75
            c0,0.28,0.05,0.53,0.14,0.75c0.09,0.22,0.23,0.4,0.41,0.55s0.41,0.26,0.68,0.34c0.27,0.08,0.58,0.12,0.94,0.12
            s0.67-0.04,0.94-0.12c0.27-0.08,0.49-0.19,0.67-0.34s0.32-0.33,0.41-0.55C107.13,39.21,107.17,38.96,107.17,38.67z"/>
          <path d="M108.98,37.01v1.38h2.66v0.33h-2.66v1.95h-0.36v-4h3.17v0.34H108.98z"/>
          <path d="M114.34,37v3.67h-0.36V37h-1.82v-0.33h4V37H114.34z"/>
          <path d="M120.29,36.67l1.43,3.71l1.48-3.71h0.41l-1.61,4h-0.54l-1.4-3.61l-1.4,3.61h-0.54l-1.61-4h0.4l1.48,3.71l1.42-3.71
            H120.29z"/>
          <path d="M127.86,40.67l-0.55-1.06h-2.72l-0.55,1.06h-0.4l2.09-4h0.43l2.09,4H127.86z M125.95,37.02l-1.18,2.26h2.36L125.95,37.02
            z"/>
          <path d="M129.41,38.96v1.71h-0.36v-4h1.99c0.3,0,0.57,0.02,0.78,0.07c0.22,0.05,0.39,0.12,0.53,0.22c0.14,0.1,0.24,0.22,0.3,0.36
            s0.1,0.31,0.1,0.5c0,0.3-0.08,0.54-0.24,0.72c-0.16,0.18-0.41,0.3-0.76,0.37l1.37,1.76h-0.46l-1.29-1.72
            c-0.05,0-0.11,0.01-0.17,0.01c-0.06,0-0.11,0-0.17,0H129.41z M132.38,37.82c0-0.17-0.03-0.31-0.09-0.42
            c-0.06-0.11-0.16-0.19-0.29-0.25s-0.31-0.1-0.54-0.12c-0.22-0.02-0.49-0.03-0.81-0.03h-1.24v1.61h1.23
            c0.32,0,0.59-0.01,0.81-0.03s0.4-0.06,0.54-0.11c0.14-0.06,0.24-0.14,0.3-0.24C132.35,38.13,132.38,37.99,132.38,37.82z"/>
          <path d="M134.01,40.67v-4h3.33v0.34h-2.94v1.38h2.79v0.33h-2.79v1.61h2.98v0.34H134.01z"/>
          <path d="M145.12,38.67c0,0.26-0.03,0.48-0.08,0.68s-0.13,0.37-0.23,0.52s-0.21,0.27-0.35,0.38c-0.14,0.1-0.29,0.19-0.46,0.25
            c-0.17,0.06-0.35,0.11-0.55,0.14c-0.2,0.03-0.41,0.04-0.63,0.04h-2.06v-4h2.06c0.33,0,0.63,0.03,0.91,0.1
            c0.28,0.06,0.53,0.17,0.73,0.33s0.37,0.36,0.49,0.62C145.06,37.97,145.12,38.29,145.12,38.67z M144.75,38.67
            c0-0.22-0.02-0.41-0.06-0.58s-0.1-0.31-0.18-0.44c-0.08-0.12-0.18-0.23-0.29-0.31c-0.12-0.08-0.24-0.15-0.39-0.2
            c-0.14-0.05-0.3-0.09-0.47-0.11C143.18,37.01,143,37,142.81,37h-1.7v3.34h1.7c0.29,0,0.55-0.03,0.78-0.08s0.44-0.14,0.61-0.27
            c0.17-0.13,0.3-0.3,0.4-0.51C144.7,39.27,144.75,39,144.75,38.67z"/>
          <path d="M146.18,40.67v-4h3.33v0.34h-2.94v1.38h2.79v0.33h-2.79v1.61h2.98v0.34H146.18z"/>
          <path d="M152.71,40.67h-0.54l-2-4h0.41l1.87,3.75l1.87-3.75h0.41L152.71,40.67z"/>
          <path d="M155.55,40.67v-4h3.33v0.34h-2.94v1.38h2.79v0.33h-2.79v1.61h2.98v0.34H155.55z"/>
          <path d="M160.06,40.67v-4h0.36v3.66h2.92v0.34H160.06z"/>
          <path d="M169.03,38.67c0,0.36-0.06,0.67-0.19,0.93c-0.13,0.26-0.31,0.48-0.54,0.65c-0.23,0.17-0.5,0.3-0.8,0.38
            c-0.31,0.08-0.64,0.12-1,0.12c-0.36,0-0.69-0.04-1-0.12c-0.31-0.08-0.58-0.21-0.81-0.38c-0.23-0.17-0.41-0.38-0.54-0.65
            c-0.13-0.26-0.2-0.57-0.2-0.93c0-0.24,0.03-0.46,0.09-0.65c0.06-0.2,0.14-0.37,0.25-0.52s0.24-0.29,0.39-0.4
            c0.15-0.11,0.33-0.21,0.51-0.28c0.19-0.08,0.39-0.13,0.61-0.17c0.22-0.04,0.45-0.05,0.68-0.05c0.36,0,0.69,0.04,1,0.12
            c0.31,0.08,0.58,0.21,0.8,0.38c0.23,0.17,0.41,0.38,0.54,0.65C168.97,38.01,169.03,38.32,169.03,38.67z M168.66,38.67
            c0-0.28-0.05-0.53-0.14-0.75c-0.09-0.22-0.23-0.4-0.41-0.55s-0.41-0.26-0.67-0.34c-0.27-0.08-0.58-0.12-0.94-0.12
            s-0.67,0.04-0.94,0.12c-0.27,0.08-0.5,0.19-0.68,0.34s-0.32,0.33-0.41,0.55c-0.09,0.22-0.14,0.47-0.14,0.75
            c0,0.28,0.05,0.53,0.14,0.75c0.09,0.22,0.23,0.4,0.41,0.55s0.41,0.26,0.68,0.34c0.27,0.08,0.58,0.12,0.94,0.12
            s0.67-0.04,0.94-0.12c0.27-0.08,0.49-0.19,0.67-0.34s0.32-0.33,0.41-0.55C168.62,39.21,168.66,38.96,168.66,38.67z"/>
          <path d="M174,37.9c0,0.2-0.03,0.38-0.1,0.53c-0.06,0.15-0.17,0.28-0.31,0.39s-0.32,0.18-0.54,0.24c-0.22,0.05-0.49,0.08-0.8,0.08
            h-1.8v1.54h-0.36v-4h2.16c0.31,0,0.58,0.03,0.8,0.08c0.22,0.05,0.4,0.13,0.54,0.23s0.24,0.23,0.31,0.38
            C173.97,37.52,174,37.7,174,37.9z M173.63,37.9c0-0.19-0.03-0.34-0.09-0.46c-0.06-0.12-0.16-0.21-0.29-0.27
            c-0.13-0.06-0.3-0.1-0.5-0.13s-0.44-0.03-0.71-0.03h-1.57v1.78h1.57c0.11,0,0.22,0,0.34,0s0.24,0,0.36-0.02
            c0.12-0.01,0.23-0.04,0.34-0.07c0.11-0.03,0.2-0.08,0.29-0.15c0.08-0.07,0.15-0.15,0.2-0.26C173.6,38.2,173.63,38.06,173.63,37.9
            z"/>
          <path d="M180.07,40.67v-3.4l-2.2,3.4h-0.32l-2.2-3.42v3.42h-0.36v-4h0.39l2.35,3.62l2.32-3.62h0.39v4H180.07z"/>
          <path d="M181.78,40.67v-4h3.33v0.34h-2.94v1.38h2.79v0.33h-2.79v1.61h2.98v0.34H181.78z"/>
          <path d="M190.12,40.67l-3.48-3.52v3.52h-0.36v-4h0.4l3.48,3.53v-3.53h0.36v4H190.12z"/>
          <path d="M193.48,37v3.67h-0.36V37h-1.82v-0.33h4V37H193.48z"/>
        </g>
        <line stroke="#000000" strokeMiterlimit="10" x1="48.43" y1="30.16" x2="194.73" y2="30.16"/>
        <g>
          <path d="M46.48,25.7V11.22h14.65v3.09H50.49v2.39h10.09v3.09H50.49v2.82h10.79v3.09H46.48z"/>
          <path d="M80.02,25.7l-9.77-9.95v9.95h-3.91V11.22h4.25l9.78,9.99v-9.99h3.89V25.7H80.02z"/>
          <path d="M98.19,14.37V25.7h-3.91V14.37h-6.14v-3.15h16.2v3.15H98.19z"/>
          <path d="M108.22,25.7V11.22h3.91v11.3h10.57v3.17H108.22z"/>
          <path d="M127.12,25.7V11.22h3.91V25.7H127.12z"/>
          <path d="M146.57,25.7h-4.55l-7.32-14.48h4.36l5.27,10.89l5.26-10.89h4.36L146.57,25.7z"/>
          <path d="M157.61,25.7V11.22h14.65v3.09h-10.64v2.39h10.09v3.09h-10.09v2.82h10.79v3.09H157.61z"/>
          <path d="M191.15,25.7l-9.77-9.95v9.95h-3.91V11.22h4.25l9.78,9.99v-9.99h3.89V25.7H191.15z"/>
        </g>
      </g>
    <g>
		<g>
			<g>
				<polygon fill="#3077E1" points="31.57,10.65 37.9,8.32 37.9,20.2 27.61,26.14 27.61,14.26"/>
				<polygon fill="#408DFF" points="17.32,8.32 27.61,14.26 37.9,8.32 27.61,2.38 				"/>
			</g>
      <line fill="none" stroke="#408DFF" strokeMiterlimit="10" x1="17.6" y1="8.32" x2="17.6" y2="33.96"/>
		</g>
		<g>
			<g>
				<polygon fill="#F79A44" points="25.93,41.45 15.66,35.52 10.09,37.15 5.39,41.45 15.66,47.38 				"/>
				<polygon fill="#408DFF" points="15.66,11.8 15.66,35.52 5.39,41.45 5.39,17.73 				"/>
			</g>
			<line fill="#F69539" stroke="#F69539" strokeMiterlimit="10" x1="5.66" y1="17.73" x2="5.66" y2="41.45"/>
		</g>
	  </g>
    </g>
    </svg>
  );
};

export default Logo;
