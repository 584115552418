import React, { Component } from "react";
import Section1 from "./images/section-1.svg";
import Section3 from "./images/section-3.svg";
import Service1 from "./images/service-1.svg";
import Service2 from "./images/service-2.svg";
import Service3 from "./images/service-3.svg";
import Service4 from "./images/service-4.svg";
import Service5 from "./images/service-5.svg";
import Service6 from "./images/service-6.svg";
import Service7 from "./images/service-7.svg";
// import Service8 from "./images/service-8.svg";

import Product1 from "./images/product-1.png";
import IconChevronRight from "../../components/SVG/IconChevronRight";
import Reveal from "react-reveal/Reveal";
import FloatOrangeRight from "../../assets/float-orange-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";
import FloatBlueRight from "../../assets/float-blue-right.svg";
import FloatBlueLeft from "../../assets/float-blue-left.svg";
import { Helmet } from "react-helmet";
import { scroller } from "react-scroll";

export default class Development extends Component {
  constructor(props) {
    super();
    this.state = {
      products: [
        {
          id: 1,
          name: "MOBILE APPLICATION DEVELOPMENT",
          desc:
            "Sebuah proses untuk mengembangkan perangkat seluler, untuk menjadi asisten digital pribadi, asisten digital perusahaan, atau sebagai telepon seluler. Di masa kini, Anda tidak hanya membutuhkan website development tetapi juga membutuhkan pengembangan mobile application. Dimana penggunaan internet dari perangkat seluler sangat tinggi penggunanya dan saat ini belanja online telah mengalami perkembangan yang pesat. Untuk mengatasi kendala dalam mengembangkan bisnis Anda, kami menyediakan pengembangan mobile application untuk memasarkan produk Anda, membantu Anda memilih desain mobile user interface (UI) yang sangat memengaruhi kenyamanan konsumen Anda dan membangun sistem yang aman untuk bisnis Anda.",
        },
        {
          id: 2,
          name: "SEO/SEM SPECIALIST",
          desc:
            "Mesin Pencari (SEO / SEM) yang berfungsi untuk merencanakan, melaksanakan dan mengelola perusahaan secara keseluruhan. Strategi ini dapat mengembangkan Search Engine Marketing Spesialis (SEO / SEM) yang paling sesuai dengan kebutuhan Anda, sangatlah penting untuk menulis Search Engine Marketing yang jelas dan tepat agar pencarian produk atau jasa Anda mudah ditemukan.",
        },
        {
          id: 3,
          name: "STRATEGI/CONSULTING",
          desc:
            "Kami membangun sistem Anda berdasarkan strategi yang unik dan kreatif. Strategi yang kami miliki memilikir resiko rendah untuk bisnis anda, buatlah bisnis Anda lebih efisien, lebih efektif, dan lebih kuat.",
        },
        {
          id: 4,
          name: "DIGITAL MARKETING",
          desc:
            "Sebuah strategi yang berhubungan dengan keefektifan pemasaran secara digital adalah konten pemasaran. Kami dapat mengirimkan konten pemasaran kepada konsumen yang membutuhkan atau melakukan pencarian terhadap produk Anda, sehingga konten pemasaran yang kami kirim dapat membuat merek Anda lebih relevan dengan sasaran konsumen, serta lebih terlihat oleh konsumen.",
        },
        {
          id: 5,
          name: "GROW UP TRAFFIC",
          desc:
            "Kami menyediakan layanan yang dapat membantu Anda meningkatkan kunjungan situs website dengan cepat agar kinerja dan keuntungan bisnis Anda.",
        },
      ],
    };
  }
  componentDidMount() {
    window.scroll(0, 0);
    this.getProducts(false);
  }

  getProducts(e) {
    if (e) {
      this.setState({
        product: e,
      });
    } else {
      this.setState({
        product: this.state.products[0],
      });
    }
  }

  scrollTo(pagename) {
    scroller.scrollTo(pagename, {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -150,
    });
  }

  render() {
    return (
      <div className="development">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Development - PT Entliven Teknologi Internasional</title>
          <meta
            name="description"
            content="Kepuasan klien adalah prioritas bagi kami, lebih dari 100 proyek telah kami kerjakan dan mendapatkan tanggapan yang memuaskan dari klien kami."
          ></meta>
          <meta
            name="keywords"
            content="Solusi Terbaik Untuk Bisnis Anda, Produk Terbaik, Mobile Application Development, SEO/SEM Specialist, Strategi/Consulting, Digital Marketing, Grow Up Traffic"
          ></meta>
        </Helmet>
        <div className="header-section space-20-bottom">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 pt-sm-5">
                <Reveal effect="zoomIn">
                  <h2 className="font-weight-bold text-grey-100">
                    Kami Telah Menyelesaikan 100++ Proyek Dengan Hasil Memuaskan
                  </h2>
                  <p className="font-body text-grey-100 mb-sm-5">
                    Kepuasan klien adalah prioritas bagi kami, lebih dari 100
                    proyek telah kami kerjakan dan mendapatkan tanggapan yang
                    memuaskan dari klien kami.{" "}
                  </p>
                  <button
                    className="btn-orange-light font-body font-weight-bold text-grey-100 cursor-pointer mb-3 mb-sm-4"
                    onClick={() => this.scrollTo("layanan")}
                  >
                    Dapatkan Layanan Kami
                  </button>
                </Reveal>
              </div>
              <div className="col-sm-6">
                <img
                  className="float1 absolute float d-width-60 delay-1s"
                  src={FloatOrangeLeft}
                  alt="float1"
                ></img>
                <img
                  className="float2 absolute float d-width-30 delay-2s"
                  src={FloatOrangeRight}
                  alt="float3"
                ></img>
                <Reveal effect="zoomIn">
                  <img
                    className="header-bg img-fluid float-right"
                    src={Section1}
                    alt="header-bg"
                  ></img>
                </Reveal>
              </div>
            </div>
          </div>
        </div>

        <div className="container" name="solusi-terbaik" id="layanan">
          <div className="business-section row space-20-bottom">
            <div className="col-sm-12">
              <Reveal effect="zoomIn">
                <h2 className="font-weight-bold text-center mb-3 mb-sm-4">
                  <span className="text-grey-800">Solusi Terbaik Untuk </span>
                  <span className="text-orange-light">Bisnis Anda</span>
                </h2>
                <p className="font-body text-grey-600 text-center mb-5 pr-sm-5 pl-sm-5">
                  Kami menciptakan karakteristik website dan fungsi yang
                  berorientasi pada kebutuhan klien kami. Kami memiliki content
                  management systems (CMS), SEO & SEM, membangun bisnis dengan
                  media sosial (sebagai upaya untuk berinteraksi dengan
                  pelanggan / pengunjung dengan lebih dekat) yang membantu dalam
                  proses pengembangan bisnis Anda.
                </p>
                <p className="font-body text-grey-600 text-center mb-5 pr-sm-5 pl-sm-5">
                  Semakin berkembangnya zaman, semakin banyak persaingan pasar
                  yang harus Anda hadapi. Website development dapat membantu
                  Anda untuk bersaing di pasar, meningkatkan bisnis dan kinerja
                  penjualan Anda.
                </p>
              </Reveal>
              <div className="row justify-content-center">
                <Reveal effect="zoomIn">
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service1}
                      alt="service-1"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web eCommerce</span>
                      <br />
                      <span>for Business</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service2}
                      alt="service-2"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Marketplace</span>
                      <br />
                      <span>for Business</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service3}
                      alt="service-3"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Company</span>
                      <br />
                      <span>Profile</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service4}
                      alt="service-4"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Portofolio</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service5}
                      alt="service-5"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Portal</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service6}
                      alt="service-6"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Governement</span>
                      <br />
                      <span>Institution</span>
                    </h6>
                  </div>
                  <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service7}
                      alt="service-7"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Web Personal</span>
                    </h6>
                  </div>
                  {/* <div className="col-6 col-sm-3 text-center mb-3 mb-sm-4">
                    <img
                      className="img-fluid pl-sm-5 pr-sm-5"
                      src={Service8}
                      alt="service-8"
                    ></img>
                    <h6 className="text-grey-600">
                      <span>Infrastructure</span>
                      <br />
                      <span>as a Service</span>
                    </h6>
                  </div> */}
                  {/* <div className="col-12">
                    <button
                      className="d-block btn-blue-light font-body font-weight-bold text-grey-100 cursor-pointer mt-4 mt-sm-5 mb-5 mb-sm-0"
                      style={{ width: "auto" }}
                    >
                      Download Content
                    </button>
                  </div> */}
                </Reveal>
              </div>
            </div>
          </div>

          <div className="start-section row d-flex flex-wrap-reverse flex-lg-mowrap space-20-bottom">
            <div className="col-sm-7">
              <img
                className="float3 float absolute d-width-30 delay-3s"
                src={FloatBlueLeft}
                alt="float3"
              ></img>
              <img
                className="float4 float absolute d-width-60 delay-4s"
                src={FloatBlueRight}
                alt="float4"
              ></img>
              <img
                className="img-responsive pr-sm-5 mt-4 mt-sm-0 mb-5 mb-sm-0"
                src={Section3}
                alt="section-3"
              ></img>
            </div>
            <div className="col-sm-5 d-flex align-items-sm-center">
              <div>
                <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                  <span className="text-grey-800">Mulai Dari </span>
                  <span className="text-orange-light">Sini</span>
                </h2>
                <p className="font-body text-grey-700 mb-sm-5 text-center text-lg-left">
                  Jika Anda mengalami kesulitan dalam membangun dan
                  mengembangkan website development perusahaan Anda , inilah
                  saat yang tepat bagi Anda untuk menghubungi kami dan dapatkan
                  pelayanan terbaik, demi kemajuan serta efisiensi perusahaan
                  Anda.
                </p>
              </div>
            </div>
          </div>

          <div
            className="product-section row space-20-bottom"
            id="produk-terbaik"
          >
            <div className="col-sm-6">
              <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                <span className="text-grey-900">Produk Terbaik </span>
                <span className="text-orange-light">Kami</span>
              </h2>
              {/* <p className="font-body text-grey-600 mb-4 text-center text-lg-left">
                ENTLIVEN is modern technology application and we are here to
                answer your business needs with the ease of using information
                technology today modern technology.
              </p> */}
              <div className="d-none d-sm-block">
                <div className="row">
                  <div className="col-12 d-flex align-items-sm-center">
                    <img
                      className="img-fluid mb-3"
                      src={Product1}
                      alt="product1"
                    ></img>
                  </div>
                  <div className="col-12">
                    {this.state.product ? (
                      <div>
                        <h6 className="font-weight-bold">
                          {this.state.product.name}
                        </h6>
                        <p className="font-body text-grey-700">
                          {this.state.product.desc}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              {this.state.products.map((e) => (
                <div key={e.id}>
                  {this.state.product ? (
                    <div>
                      <button
                        className={
                          e.id === this.state.product.id
                            ? "bg-orange-light btn-select mb-4"
                            : "btn-select mb-4"
                        }
                        onClick={() => this.getProducts(e)}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <span
                            className={
                              e.id === this.state.product.id
                                ? "text-white"
                                : "text-grey-700"
                            }
                          >
                            {e.name}
                          </span>
                          <IconChevronRight
                            size="32"
                            color={
                              e.id === this.state.product.id
                                ? "#FFFFFF"
                                : "#FF9933"
                            }
                          />
                        </div>
                      </button>
                      <div
                        className={
                          e.id === this.state.product.id
                            ? "row d-sm-none"
                            : "d-none"
                        }
                      >
                        <div className="col-12">
                          <div>
                            <p className="font-body text-grey-700">
                              {this.state.product.desc}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
