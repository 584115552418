import React from 'react';

import NewsContent1 from './images/news-content1.png';
import Reveal from 'react-reveal/Reveal';

const NewsCard = (props) => {
    return (
        <div className="news-card mb-5">
            <Reveal effect="zoomIn">
                <img className="img-responsive mb-4" src={NewsContent1} alt="new content"></img>
                <h6 className="font-weight-bold">Cara menjadi Seorang grafik design</h6>
                <p className="font-body text-grey-600 mb-4">14/03/2019 15.00 A.M.</p>
                <p className="font-body text-grey-800 mb-4">
                    ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today.
                    ENTLIVEN is modern technology application and we are here to answer your business needs with the ease of using information technology today.
                </p>
                <button className={"font-body font-weight-bold text-grey-100 cursor-pointer mb-sm-5 " + (props.type === "recent" ? 'btn-blue-light' : 'btn-outline-orange-light')}>Read More</button>
            </Reveal>
        </div>
    )
};

export default NewsCard;
