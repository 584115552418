import React, { Component } from "react";
import SectionImg from "./images/section-started.svg";
import FloatOrangeRight from "../../assets/float-orange-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";

import Reveal from "react-reveal/Reveal";

export default class StartedSection extends Component {
  render() {
    return (
      <div
        className="started-section space-20-bottom"
        name="get-started"
        id="get-started"
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-5 d-flex align-items-sm-center mb-5 mb-sm-0">
              <div>
                <Reveal effect="zoomIn">
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                    <span className="text-black">
                      We are not perfect, but we always{" "}
                    </span>
                    <span className="text-orange-light">
                      try and develop to be the best.
                    </span>
                  </h2>
                  <p className="font-body text-grey-600 mb-5 text-center text-lg-left"></p>
                  <div className="d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-left">
                    <a href="/Company Profile 15 (Digital).pdf">
                      <button className="d-block btn-orange-light font-body font-weight-bold text-grey-100 cursor-pointer m-sm-0 mb-3 mb-sm-0">
                        Lihat Profil Kami
                      </button>
                    </a>
                    <a href="tel:+62895703193208">
                      <button className="d-block btn-outline-orange-light font-body font-weight-bold text-grey-100 cursor-pointer ml-sm-3">
                        Hubungi Kami
                      </button>
                    </a>
                  </div>
                </Reveal>
              </div>
            </div>
            <div className="col-sm-7">
              <img
                className="float1 absolute set-backward float d-width-50 delay-1s"
                src={FloatOrangeLeft}
                alt="float1"
              ></img>
              <img
                className="float2 absolute float d-width-60 delay-2s"
                src={FloatOrangeRight}
                alt="float3"
              ></img>
              <Reveal effect="zoomIn">
                <div className="section-started pl-lg-5">
                  <img
                    className="section-started img-responsive pl-sm-5"
                    src={SectionImg}
                    alt="section-started"
                  ></img>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
