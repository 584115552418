import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Section1 from "./images/section-1.svg";
import Section2 from "./images/section-2.svg";
import Section3 from "./images/section-3.svg";
import IconMail from "./images/icon-mail.svg";
import IconPhone from "./images/icon-phone.svg";

import Reveal from "react-reveal/Reveal";
import FloatOrangeRight from "../../assets/float-orange-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";
import FloatBlueRight from "../../assets/float-blue-right.svg";
import FloatBlueLeft from "../../assets/float-blue-left.svg";
import { scroller } from "react-scroll";

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (e) => {
    this.setState({ subject: e.target.subject, message: e.target.message });
  };

  handleSubmit(e) {
    window.open(
      `mailto:hi@entliven.com?subject=${this.state.subject}&body=${this.state.message}`
    );
    e.preventDefault();
  }

  componentDidMount() {
    window.scroll(0, 0);
  }

  scrollTo(pagename) {
    scroller.scrollTo(pagename, {
      duration: 1500,
      delay: 0,
      smooth: true,
      offset: -150,
    });
  }

  render() {
    return (
      <div className="contact">
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us - PT Entliven Teknologi Internasional</title>
            <meta
              name="description"
              content="Menjawab kebutuhan Anda merupakan hal yang penting bagi kami. Temukan solusi yang akan membantu bisnis Anda terus bertumbuh dan mencapai target secara maksimal."
            ></meta>
            <meta
              name="keywords"
              content="Temukan Kami Disini, Terhubung dengan Kami, Tanyakan Pada Kami"
            ></meta>
          </Helmet>
          <div className="row d-flex flex-wrap-reverse flex-lg-mowrap space-20-bottom">
            <Reveal effect="zoomIn">
              <div className="col-sm-6 mt-5">
                <img
                  className="float1 float absolute d-width-40 delay-1s"
                  src={FloatOrangeLeft}
                  alt="float1"
                ></img>
                <img
                  className="float2 float absolute d-width-30 delay-2s"
                  src={FloatOrangeRight}
                  alt="float2"
                ></img>
                <img
                  className="float3 float absolute d-width-50 delay-3s"
                  src={FloatBlueLeft}
                  alt="float3"
                ></img>
                <img
                  className="float4 float absolute d-width-20 delay-4s"
                  src={FloatBlueRight}
                  alt="float4"
                ></img>
                <img
                  className="float5 float absolute d-width-40 delay-2s"
                  src={FloatOrangeRight}
                  alt="float5"
                ></img>
                <img
                  className="header-bg img-responsive pr-sm-5"
                  src={Section1}
                  alt="header-bg"
                ></img>
              </div>
              <div className="col-sm-6 d-flex align-items-sm-center">
                <div>
                  <h2 className="font-weight-bold mb-3 mb-sm-4 mt-4 mt-sm-0 text-center text-sm-left">
                    <span className="text-grey-900">Tetaplah </span>
                    <span className="text-blue-light">Berhubungan</span>
                  </h2>
                  <p className="font-body text-grey-800 mb-5 mb-sm-4 text-center text-sm-left">
                    Menjawab kebutuhan Anda merupakan hal yang penting bagi
                    kami. Temukan solusi yang akan membantu bisnis Anda terus
                    bertumbuh dan mencapai target secara maksimal.{" "}
                  </p>
                  <div className="d-sm-flex justify-content-center justify-content-sm-start text-center text-sm-left">
                    <button
                      className="d-block btn-outline-blue-dark font-body font-weight-bold text-grey-100 cursor-pointer ml-sm-0"
                      onClick={() => this.scrollTo("hubungi-kami")}
                    >
                      Hubungi Kami
                    </button>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>

          <div className="contact-section row space-20-bottom">
            <Reveal effect="zoomIn">
              <div
                className="col-sm-6 d-flex align-items-sm-center"
                id="lokasi"
              >
                <div>
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-sm-left">
                    <span className="text-grey-900">Temukan </span>
                    <span className="text-blue-light">Kami </span>
                    <span className="text-blue-light">Disini</span>
                  </h2>
                  <p className="font-body text-grey-800 text-center text-sm-left">
                    Jalan RC. Veteran No.1l Bintaro, Pesanggrahan, Kota Jakarta
                    Selatan, Daerah Khusus Ibukota Jakarta
                  </p>
                  <a
                    href="https://g.page/bbcofficesuite?share"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="d-block btn-outline-blue-dark font-body font-weight-bold text-grey-100 cursor-pointer mt-5 mt-sm-4 ml-sm-0 mb-5 mb-sm-0">
                      Find Us
                    </button>
                  </a>
                </div>
              </div>
              <div className="col-sm-6 mt-4 mt-sm-0">
                <img
                  className="section-2 img-responsive"
                  src={Section2}
                  alt="section-2"
                ></img>
              </div>
            </Reveal>
          </div>

          <div
            className="form-section row mt-5 mb-5 d-flex flex-wrap-reverse flex-lg-mowrap space-20-bottom"
            name="hubungi-kami"
            id="hubungi-kami"
          >
            <Reveal effect="zoomIn">
              <div className="col-sm-7">
                <img
                  className="section-3 img-responsive  pr-sm-5"
                  src={Section3}
                  alt="section-3"
                ></img>
              </div>
              <div className="col-sm-5" id="kontak">
                <div>
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-sm-left">
                    <span className="text-grey-900">Terhubung dengan </span>
                    <span className="text-blue-light">Kami</span>
                  </h2>
                  <p className="font-body text-grey-800 text-center text-sm-left">
                    Dapatkan berita dan pembaruan tentang layanan perusahaan,
                    produk dan penawaran terkini dari kami. Terhubunglah dengan
                    media sosial kami, agar Anda dapat menjangkau kami dengan
                    lebih cepat dan mudah.
                  </p>
                  <p className="font-body text-blue-light font-weight-bold mb-5 space-30-bottom text-center text-sm-left">
                    <span>
                      <a href="tel:+62895703193208" className="text-blue-light">
                        <img
                          src={IconPhone}
                          height="24"
                          className="mr-1"
                          alt="phone"
                        />{" "}
                        +62 895-7031-93208
                      </a>
                    </span>
                    <span className="ml-5">
                      <a
                        href="mailto:hi@entliven.com"
                        className="text-blue-light"
                      >
                        <img
                          src={IconMail}
                          height="20"
                          className="mr-1"
                          alt="mail"
                        />{" "}
                        hi@entliven.com
                      </a>
                    </span>
                  </p>
                  <h2 className="font-weight-bold mb-4 mb-sm-4 text-center text-sm-left">
                    <span className="text-grey-900">Tanyakan </span>
                    <span className="text-blue-light">Pada </span>
                    <span className="text-grey-900">Kami</span>
                  </h2>
                  <p className="font-body text-grey-800 text-center text-sm-left">
                    Tinggalkan pertanyaan Anda, dengan senang hati kami akan
                    menjawab setiap kebutuhan Anda dengan cepat.
                  </p>
                  <form className="mb-5" onSubmit={this.handleSubmit}>
                    <input
                      type="text"
                      className="form-control mb-4"
                      placeholder="Input Your Mail Subject Here"
                      value={this.state.subject}
                      onChange={this.handleChange}
                    ></input>
                    <textarea
                      className="form-control mb-5"
                      placeholder="Input Your Message Here"
                      rows="5"
                      value={this.state.message}
                      onChange={this.handleChange}
                    ></textarea>
                    <button
                      className="d-block btn-blue-light font-body font-weight-bold text-grey-100 cursor-pointer ml-sm-0"
                      type="submit"
                    >
                      Send
                    </button>
                  </form>
                </div>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    );
  }
}
