import React, { Component } from "react";
import IconIg from "./images/instagram.svg";
import IconTwitter from "./images/twitter.svg";
import IconFb from "./images/facebook.svg";
import IconMail from "./images/mail.svg";
import IconPhone from "./images/phone.svg";

import Reveal from "react-reveal/Reveal";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <div className="row pt-3">
            <Reveal effect="zoomIn">
              <div className="col-sm-4 col-xs-12 pr-sm-5">
                <p className="font-small text-grey-100 font-weight-bold">
                  PT Entliven Teknologi International
                </p>
                <p className="font-small text-grey-100">
                  Jalan RC. Veteran No.1l Bintaro, Pesanggrahan, Kota Jakarta
                  Selatan, Daerah Khusus Ibukota Jakarta
                </p>
                <p className="font-small text-grey-100 font-weight-bold">
                  <a href="tel:+62895703193208" className="text-grey-100">
                    <img
                      src={IconPhone}
                      height="20"
                      className="mr-1"
                      alt="phone"
                    ></img>
                    +62 895-7031-93208
                  </a>
                </p>
                <p className="font-small text-grey-100 font-weight-bold">
                  <a href="mailto:hi@entliven.com" className="text-grey-100">
                    <img
                      src={IconMail}
                      height="20"
                      className="mr-1"
                      alt="phone"
                    ></img>
                    hi@entliven.com
                  </a>
                </p>
              </div>
            </Reveal>
            <div className="col-sm-3 col-6">
              <Reveal effect="zoomIn">
                <div className="mb-4 mb-sm-5 pl-sm-5">
                  <p className="font-small text-grey-100 font-weight-bold">
                    Development
                  </p>
                  <a href="/development#layanan">
                    <p className="font-small no-margin text-grey-100">
                      Layanan
                    </p>
                  </a>
                  <a href="/development#produk-terbaik">
                    <p className="font-small text-grey-100">Produk Terbaik</p>
                  </a>
                </div>
              </Reveal>

              <div className="mb-4 mb-sm-5 pl-sm-5">
                <Reveal effect="zoomIn">
                  <p className="font-small text-grey-100 font-weight-bold">
                    Client
                  </p>
                  <a href="/client#pemerintah-daerah">
                    <p className="font-small no-margin text-grey-100">
                      Pemerintah Daerah
                    </p>
                  </a>
                  <a href="/client#pemerintah-pusat">
                    <p className="font-small no-margin text-grey-100">
                      Pemerintah Pusat - Kementerian
                    </p>
                  </a>
                  <a href="/client#perusahaan-swasta">
                    <p className="font-small no-margin text-grey-100">
                      Perusahaan Swasta
                    </p>
                  </a>
                </Reveal>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="mb-4 mb-sm-5 pl-sm-5">
                <Reveal effect="zoomIn">
                  <p className="font-small text-grey-100 font-weight-bold">
                    About Us
                  </p>
                  <a href="/about#visi-misi">
                    <p className="font-small no-margin text-grey-100">
                      Visi dan Misi
                    </p>
                  </a>
                  <a href="/about#sejarah">
                    <p className="font-small no-margin text-grey-100">
                      Sejarah
                    </p>
                  </a>
                </Reveal>
              </div>

              <div className="mb-4 mb-sm-5 pl-sm-5">
                <Reveal effect="zoomIn">
                  <p className="font-small text-grey-100 font-weight-bold">
                    Contact Us
                  </p>
                  <a href="/contact#lokasi">
                    <p className="font-small no-margin text-grey-100">Lokasi</p>
                  </a>
                  <a href="/contact#kontak">
                    <p className="font-small text-grey-100">Kontak</p>
                  </a>
                </Reveal>
              </div>
            </div>
            <div className="col-sm-2 col-6 pb-3">
              <Reveal effect="zoomIn">
                <div className="d-flex">
                  <a
                    href="https://www.facebook.com/entliven/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="mr-2"
                      src={IconFb}
                      alt="ic-fb"
                      height="40"
                    ></img>
                  </a>
                  <a
                    href="https://twitter.com/entliven?lang=id"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="mr-2"
                      src={IconTwitter}
                      height="40"
                      alt="ic-ig"
                    ></img>
                  </a>
                  <a
                    href="https://www.instagram.com/entliven_id/?hl=id"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={IconIg} height="40" alt="ic-ig"></img>
                  </a>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
        <div className="border-top pt-4 pb-4">
          <Reveal effect="zoomIn">
            <p className="font-small text-grey-100 font-weight-bold text-center no-margin">
              &copy; {new Date().getFullYear()}. All Rights Reserved
            </p>
          </Reveal>
        </div>
      </div>
    );
  }
}
