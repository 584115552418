import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Section1 from "./images/section-1.svg";
import Section2 from "./images/section-2.svg";
import Section3 from "./images/section-3.svg";
import FloatOrangeRight from "../../assets/float-orange-right.svg";
import FloatOrangeLeft from "../../assets/float-orange-left.svg";
import FloatBlueRight from "../../assets/float-blue-right.svg";
import FloatBlueLeft from "../../assets/float-blue-left.svg";
import FloatParticle1 from "../../assets/float-particle1.svg";
import FloatParticle2 from "../../assets/float-particle2.svg";

import Clients from "./images/clients.svg";
import Reveal from "react-reveal/Reveal";

export default class Client extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <div className="client">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Client - PT Entliven Teknologi Internasional</title>
          <meta
            name="description"
            content="Kami membangun kepercayaan klien berdasarkan pengalaman dan kolaborasi bersama tim ahli kami. Kepuasan klien adalah prioritas bagi kami, sehingga kami memberikan pelayanan, harga, dan kualitas produk yang terbaik untuk setiap klien kami. Beberapa dari klien kami bisa Anda temukan disini."
          ></meta>
          <meta
            name="keywords"
            content="Pemerintah Daerah, Pemerintah Pusat - Kementerian, Perusahaan Swasta"
          ></meta>
        </Helmet>
        <div className="header-section space-20-bottom">
          <div className="container">
            <Reveal effect="zoomIn">
              <div className="row d-flex flex-wrap-reverse flex-lg-mowrap">
                <div className="col-sm-7">
                  <img
                    className="float1 float absolute d-width-70 delay-1s"
                    src={FloatBlueLeft}
                    alt="float1"
                  ></img>
                  <img
                    className="float2 float absolute d-width-30 delay-2s"
                    src={FloatBlueRight}
                    alt="float2"
                  ></img>
                  <img
                    className="header-bg img-responsive"
                    src={Section1}
                    alt="header-bg"
                  ></img>
                </div>
                <div className="col-sm-5 d-flex align-items-sm-center">
                  <div>
                    <h2 className="font-weight-bold text-grey-100 mt-3 mt-sm-0 mb-3 mb-sm-4">
                      Mengenal Klien Kami
                    </h2>
                    <p className="font-body text-grey-100 mb-3 mb-sm-4">
                      Kami membangun kepercayaan klien berdasarkan pengalaman
                      dan kolaborasi bersama tim ahli kami. Kepuasan klien
                      adalah prioritas bagi kami, sehingga kami memberikan
                      pelayanan, harga, dan kualitas produk yang terbaik untuk
                      setiap klien kami. Beberapa dari klien kami bisa Anda
                      temukan disini.
                    </p>
                  </div>
                </div>
              </div>
            </Reveal>
          </div>
        </div>

        <div className="container">
          <div className="row space-20-bottom" id="pemerintah-daerah">
            <Reveal effect="zoomIn">
              <div className="col-sm-6 d-flex align-items-sm-center">
                <div>
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                    <span className="text-grey-900">Pemerintah </span>
                    <span className="text-blue-light">Daerah</span>
                  </h2>
                  <p className="font-body text-grey-600 text-center text-lg-left mb-5 mb-sm-0">
                    Kami membantu pemerintah daerah dalam mengerjakan pembuatan
                    sistem manajemen rumah sakit dalam pengembangan Enterprise
                    Resource Planning (ERP) berdasarkan peraturan Menteri
                    Kesehatan Republik Indonesia - Nomor 82 Tahun 2013.
                  </p>
                </div>
              </div>
              <div className="col-sm-6">
                <img
                  className="float3 float absolute d-width-60 slower delay-1s"
                  src={FloatOrangeLeft}
                  alt="float3"
                ></img>
                <img
                  className="float4 float absolute d-width-30 slower delay-2s"
                  src={FloatOrangeRight}
                  alt="float4"
                ></img>
                <img
                  className="img-responsive"
                  src={Section2}
                  alt="section2"
                ></img>
              </div>
            </Reveal>
          </div>
          <div
            className="row space-20-bottom  d-flex flex-wrap-reverse flex-lg-mowrap"
            id="pemerintah-pusat"
          >
            <Reveal effect="zoomIn">
              <div className="col-sm-6">
                <img
                  className="float3 float absolute d-width-30 delay-1s"
                  src={FloatBlueLeft}
                  alt="float3"
                ></img>
                <img
                  className="float4 float absolute d-width-60 delay-2s"
                  src={FloatBlueRight}
                  alt="float4"
                ></img>
                <img
                  className="img-responsive"
                  src={Section3}
                  alt="section3"
                ></img>
              </div>
              <div className="col-sm-6 d-flex align-items-sm-center">
                <div>
                  <h2 className="font-weight-bold mb-3 mb-sm-4 text-center text-lg-left">
                    <span className="text-grey-900">Pemerintah </span>
                    <span className="text-blue-light">Pusat </span>
                    <span className="text-grey-900">- Kementerian </span>
                  </h2>
                  <p className="font-body text-grey-600 text-center text-lg-left mb-5 mb-sm-0">
                    Membuat sistem pendaftaran untuk project Kementerian yang
                    meliputi reservasi online dengan kode QR. Selain itu, kami
                    juga mengerjakan Sistem Akses Kinerja, manajemen, dan
                    kepemimpinan yang dilaksanakan oleh pemerintah pusat dalam
                    keputusan Presiden Republik Indonesia guna membangun semua
                    aspek transparansi dengan Sistem E-Government, dan membangun
                    sistem deteksi radar untuk pasukan khusus Marinir - Angkatan
                    Laut.
                  </p>
                </div>
              </div>
            </Reveal>
          </div>
          <div
            className="section-clients space-20-bottom"
            id="perusahaan-swasta"
          >
            <img
              className="float-particle1"
              src={FloatParticle1}
              alt="float-particle1"
            ></img>
            <img
              className="float-particle2"
              src={FloatParticle2}
              alt="float-particle2"
            ></img>
            <div className="row mb-5">
              <Reveal effect="zoomIn">
                <div className="col-sm-6 offset-sm-3 text-center">
                  <div>
                    <h2 className="font-weight-bold mb-3 mb-sm-4">
                      <span className="text-grey-900">Perusahaan </span>
                      <span className="text-blue-light">Swasta</span>
                    </h2>
                    <p className="font-body text-grey-600 text-center">
                      Membangun sistem informasi perbankan, sistem manajemen
                      perusahaan, sistem penjualan dengan teknologi terkini,
                      memfasilitasi laporan keuangan restoran baik harian,
                      mingguan dan bulanan yang terintegrasi secara akurat ke
                      sistem akuntansi. Kami juga menyediakan layanan mobile
                      app, penyesuaian software, aplikasi game untuk brand.
                    </p>
                  </div>
                </div>
                <div className="col-sm-8 offset-sm-2 d-flex justify-content-center">
                  <img
                    className="img-responsive"
                    src={Clients}
                    alt="section3"
                  ></img>
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
