import React from 'react';

const IconClose = ({ size = 24, color = 'black' }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.34314 6.34277L17.6568 17.6565"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M17.6569 6.34277L6.34315 17.6565"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default IconClose;
