import React from 'react';
import { animateScroll as scroll } from 'react-scroll'
import toTop from './images/to-top.png';

import Reveal from 'react-reveal/Reveal';

class ScrollTop extends React.Component {
constructor(props) {
    super(props)
    this.state = {
        isScrolling: false
    };
}

componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
}

componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
}

onScroll = () => {

    if (window.pageYOffset > window.innerHeight) {
        this.setState({ isScrolling: true });
    } else {
        this.setState({ isScrolling: false });
    }

};
    
toTop() {
    scroll.scrollToTop({
        smooth: "easeInOutQuint"
    });
}

render() {
    return (
        <div className="scroll-top" onClick={this.toTop}>
            {
                this.state.isScrolling ? 
                <Reveal effect="fadeInUp">
                    <img className="button-top" src={toTop} alt="to top"></img>
                </Reveal>
                : ''
            }
            
        </div>
    )
}
}

export default ScrollTop;
