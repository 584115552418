import React, { Component } from 'react';

import NewsContent1 from './images/news-content1.png';
import NewsCard from '../../components/NewsCard/NewsCard';

export default class News extends Component {
    
    componentDidMount() {
        window.scroll(0, 0)
    }
    
    render() {
        return (
            <div className="news mb-5">
                <div className="container">
                    <img className="img-fluid mt-5" src={NewsContent1} alt="news-content"></img>
                    <h4 className="text-center font-weight-bold mt-5 mb-3">Cara menjadi seorang grafik desain</h4>
                    <h6 className="text-center mb-5">Sintes Blogger, 17 Maret 2019 in Design</h6>

                    <div className="row mb-5">
                        <div className="col-sm-8 offset-sm-2">
                            <p className="font-body">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>

                            <p className="font-body">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <h6 className="text-center font-weight-bold mb-5">Related Post</h6>
                        </div>
                        <div className="col-sm-4">
                            <NewsCard />
                        </div>
                        <div className="col-sm-4">
                            <NewsCard />
                        </div>
                        <div className="col-sm-4">
                            <NewsCard />
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
