import React from 'react';

const LogoMenu = ({ size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path d="M4 6H20M4 12H16M4 18H18" stroke="#666666" strokeWidth="2" />
    </svg>
  );
};

export default LogoMenu;
